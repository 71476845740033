import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  showError: string = null;
  showSuccess = false;

  constructor(private router: Router) {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
      confirmPassword: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.pattern(/^[\?+]\d+$/)]),
      acceptTerms: new FormControl(false)
    })
  }

  ngOnInit(): void {
  }

  setTerms(val) {
    this.signUpForm.controls.acceptTerms.patchValue(val);
  }

  setPhone(val) {
    this.signUpForm.controls.phone.patchValue(val);
  }

  signup(event) {
    event.preventDefault();
    if (this.signUpForm.invalid) {
      return;
    }

    const params = this.signUpForm.value;

    if (!params.acceptTerms) {
      this.showError = 'Please accept the terms of service';
      return;
    }

    if (params.password !== params.confirmPassword) {
      this.showError = 'Password is not matching';
      return;
    }
    console.log(params)

    Auth.signUp({
      username: params.email,
      password: params.password,
      attributes: {
        name: params.firstName,
        middle_name: params.lastName,
        email: params.email,
        phone_number: params.phone
      }
    }).then(res => {
      this.showSuccess = true;
      var abv={
        user:params.email,
        password:params.password
      }
      setTimeout(() => this.router.navigate(['/verify'],{queryParams:abv, skipLocationChange: true }), 1000);
    }, err => {
      this.showError = err.message;
    });
  }

}
